import React from 'react';
import { Globals } from '../styles/global';
import { graphql } from 'gatsby';
import Layout from '../layouts';
import showdown from 'showdown';
import { Inner, WhiteBox, SwitchBox } from '../components/shared';
import { Box } from '@material-ui/core';
import { Breadcrumb, IBreadcrumb } from '../components/breadcrumb';
import SEO from '../components/seo';

const converter = new showdown.Converter();

type PageType =
  | 'adopting'
  | 'fostering'
  | 'system-of-care'
  | 'transition-youth'
  | 'basic';

const colorMap: { [K in PageType]: string } = {
  fostering: Globals.purple,
  adopting: Globals.yellow,
  basic: Globals.grayscale_b,
  'system-of-care': Globals.orange,
  'transition-youth': Globals.green,
};

const BasicPageTemplate2 = ({
  title,
  content,
  pageType,
  breadcrumbs,
}: {
  title: string;
  content: string;
  pageType: PageType;
  breadcrumbs?: IBreadcrumb[];
}) => {
  return (
    <>
      <SEO title={title} />
      <SwitchBox
        style={{ backgroundColor: colorMap[pageType], color: Globals.white }}
      >
        <Inner justifyContent="space-between" display="flex">
          <Box width={1}>
            <h1 className="font-u3 text-center">{title}</h1>
          </Box>
        </Inner>
      </SwitchBox>
      <WhiteBox>
        <Inner>
          <Box>
            {breadcrumbs && breadcrumbs.length > 0 && (
              <Breadcrumb breadcrumbs={breadcrumbs} />
            )}

            <div
              className="markdown-holder"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
            />
          </Box>
        </Inner>
      </WhiteBox>
    </>
  );
};

const RepeaterModalPage = ({
  data: {
    markdownRemark: {
      frontmatter: {
        title = '',
        content = '',
        pageType = 'basic',
        breadcrumbs = {},
      },
    },
  },
}) => {
  return (
    <Layout>
      <BasicPageTemplate2
        title={title}
        content={content}
        pageType={pageType as PageType}
        breadcrumbs={breadcrumbs as IBreadCrumb}
      />
    </Layout>
  );
};

export default RepeaterModalPage;

export const BasicPage2Query = graphql`
  query BasicPage2($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        content
        pageType
        breadcrumbs {
          title
          link
        }
      }
    }
  }
`;
