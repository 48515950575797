import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { Link } from 'gatsby';

export interface IBreadcrumb {
  title: string;
  link: string;
}

export const Breadcrumb = ({ breadcrumbs }: { breadcrumbs: IBreadcrumb[] }) => {
  return (
    <Wrapper>
      {breadcrumbs.map((crumb, index) => {
        return (
          <Link to={crumb.link} key={index} className="font-b mr-b">
            » {crumb.title}
          </Link>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  a {
    margin-bottom: 1rem;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
